import React from "react"
import {
    Stack,
    HStack,
    Link,
    Text,
    VStack
  } from '@chakra-ui/react';

import { Link as GatsbyLink } from "gatsby"
import { EmailIcon, ExternalLinkIcon } from '@chakra-ui/icons'

const footerMobile: React.FC = () => {
    return (
        <Stack width={'full'} 
        bgColor={'#1255cc'} 
        color={'white'}
        spacing={1} 
        paddingTop={7} 
        paddingBottom={'20px'} 
        paddingLeft={'5%'}
        paddingRight={'5%'} 
        marginTop={'100px'}
         >
          <Text fontWeight={'bold'} fontSize={'2xl'}>主催：B SIDE CLUB<Text as={'span'} fontSize={'md'}>（ベイサイド株式会社）</Text></Text>
          <HStack width={'full'} spacing={'4%'} fontSize={'sm'}>
              <Stack width={'100%'}>
                <Link fontWeight={'bold'} as={GatsbyLink} to={`/privacy`}>プライバシーポリシー</Link>
                <Link fontWeight={'bold'} href='mailto:kkaayyoo2327+bsideclub@gmail.com?subject=七里ヶ浜フリーマーケットについてのお問合せ' isExternal>お問合せ <EmailIcon mx='2px' mb='2px'/></Link>
                <Link fontWeight={'bold'} href='http://ameblo.jp/bsideclub/' isExternal>ブログ<ExternalLinkIcon mx='2px' mb='2px'/></Link>
                <Link fontWeight={'bold'} href='https://twitter.com/staff83277920/' isExternal>Twitter<ExternalLinkIcon mx='2px' mb='2px'/></Link>
                        <Link fontWeight={'bold'} href='https://www.instagram.com/fleamarket_shichiri/' isExternal>Instagram<ExternalLinkIcon mx='2px' mb='2px'/></Link>
              </Stack>
          </HStack>
          <VStack pt={'20px'}>
          <Text color={'white'}  fontSize={'sm'}>Copyright ©️ BAYSIDE CO.,LTD. All Rights Reserved.</Text>
          </VStack>
          
        </Stack>

  )
}
export default footerMobile