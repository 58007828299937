import { extendTheme } from '@chakra-ui/react'

/**
 * This theme object can be changed and edited however you like. It's currently extending the default Chakra theme
 * (which itself is inspired by Tailwind CSS) with the values below.
 * Example usage of theme values: <Text color="brand.100" fontSize="6xl" fontWeight="bold">Boop</Text>
 */
export const theme = extendTheme({
    breakpoints: {
      sm: '30em',
      md: '49em',
      lg: '62em',
      xl: '80em',
      '2xl': '96em',
    },
    fonts: {
        heading: 'Noto Sans JP, sans-serif',
        body: 'Noto Sans JP, sans-serif',
    },
    lineHeights: {
        shorter: 1.83,
        short: 1.83,
        base: 1.86,
      },
    colors: {
        black: '#202124',
        gray: {
            10: '#cbd0d3',
            30: '#f5f5f5',
            50: '#e5e5e3',
            100: '#d0d0d0',
            200: '#9b9b9b',
          },
        brown: '#44403f',
        red: '#D55E5E'
    }
})
