/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import NavBar from "./organisms/navBar"

import Footer from "./organisms/footer"
import FooterMobile from "./organisms/footerMobile"

import { Show, Stack, Flex, Image } from "@chakra-ui/react"

import scrollTopImage from "../images/scroll-top.svg"

import ScrollToTop from "../components/atoms/scrollUp";

const Layout = ({children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <NavBar></NavBar>
      <Stack h={'64px'}></Stack>
      <ScrollToTop showUnder={160} style={{
          position: 'fixed',
          bottom: 20,
          right: 'auto',
          left: 20,
          cursor: 'pointer',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'linear',
          transitionDelay: '0s'
      }}>
        <Flex h={'44px'} w={'44px'} bgColor={'#202124'} justify={'center'} align={'center'}>
          <Image src={scrollTopImage} mt={2}/>
        </Flex>
      </ScrollToTop>
      <main>{children}</main>
      <Show below="md">
        <FooterMobile />
      </Show>
      <Show above="md">
        <Footer />  
      </Show>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
