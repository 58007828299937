import React from "react"
import { ReactNode } from 'react';
import { Link as GatsbyLink } from "gatsby"
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  VisuallyHidden,
  Image,
  Stack,
  Text,
  Button,
  Show
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const handleClick = () => {
  if (location.pathname != '/') {
    window.location.href = "/#offer";
  } else {
    const element = document.getElementById("offer")!;
    element.scrollIntoView({  
        behavior: 'smooth'  
      });
  }
};

import { useLocation } from "@reach/router"
import LogoImage from "../../images/index/logo.png"
import SellLogoImage from "../../images/sell/logo.svg"
import ManageLogoImage from "../../images/manage/logo.svg"
import InvestLogoImage from "../../images/invest/logo.svg"
import LearnLogoImage from "../../images/learn/logo.svg"
import MeetLogoImage from "../../images/meet/logo.svg"

import HamburgerIcon from "../../images/burger.svg"


const moveToPlans = () => {
  if (location.pathname != '/') {
    window.location.href = "/#plans";
  } else {
    const element = document.getElementById('plans')!;
    const targetDOMRect = element.getBoundingClientRect();
    const targetTop = targetDOMRect.top + window.pageYOffset - 60;
    window.scrollTo({
      top: targetTop,
      behavior: 'smooth'
    });
  }
};

const moveToNews = () => {
  if (location.pathname != '/') {
    window.location.href = "/#news";
  } else {
    const element = document.getElementById('news')!;
    const targetDOMRect = element.getBoundingClientRect();
    const targetTop = targetDOMRect.top + window.pageYOffset - 60;
    window.scrollTo({
      top: targetTop,
      behavior: 'smooth'
    });
  }
};

const moveToNotices = () => {
  if (location.pathname != '/') {
    window.location.href = "/#notice";
  } else {
    const element = document.getElementById('notice')!;
    const targetDOMRect = element.getBoundingClientRect();
    const targetTop = targetDOMRect.top + window.pageYOffset - 60;
    window.scrollTo({
      top: targetTop,
      behavior: 'smooth'
    });
  }
};

const NavBar: React.FC = (pageContext) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  let logo = LogoImage
  let heading = 'inove'

  const isActive = (url) => {
    if (location.pathname == '/' && url == '/') {
      return true
    } else if (location.pathname.match(/^\/manage/) && url == '/manage') {
      return true
    } else if (location.pathname.match(/^\/invest/) && url == '/invest') {
      return true
    } else if (location.pathname.match(/^\/sell/) && url == '/sell') {
      return true
    } else if (location.pathname.match(/^\/learn/) && url == '/learn') {
      return true
    } else if (location.pathname.match(/^\/meet/) && url == '/meet') {
      return true
    } else if (location.pathname.match(/^\/contact/) && url == '/contact') {
      return true
    } 
    return false
  }

  return (
    <>
      <Box bg={'white'} shadow={'sm'} w={'full'} position={'fixed'} top={0} zIndex={'1000'}>
        <Flex h={16} mx={'auto'} px={5} alignItems={'center'} justifyContent={'space-between'} maxW={'1160px'}>
          <IconButton
            bg={'white'}
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <Image src={HamburgerIcon}/>}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <GatsbyLink to={'/'}>
              <HStack>
                <Show above="md">
                  <Image src={logo} h={12} mr={'10px'} />
                </Show>
                <Text fontWeight={"bold"}>七里ヶ浜フリーマーケット</Text>
              </HStack>
            </GatsbyLink>
          
          <VisuallyHidden as={location.pathname.match(/.+\/.+/) ? 'p' : 'h1'}>{heading}</VisuallyHidden>
          <Flex alignItems={'center'}>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
                <Text
                  fontSize={'16px'}
                  color={'#3c78d8'}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'white',
                  }}
                  cursor={'pointer'}
                  onClick={moveToPlans}>
                  出店プラン
                </Text>
                <Text
                  fontSize={'16px'}
                  color={'#3c78d8'}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'white',
                  }}
                  cursor={'pointer'}
                  onClick={moveToNews}>
                  ニュース
                </Text>
                <Text
                  fontSize={'16px'}
                  color={'#3c78d8'}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'white',
                  }}
                  cursor={'pointer'}
                  onClick={moveToNotices}
                  >
                  注意事項
                </Text>
            </HStack>
          </Flex>
          </HStack>
          <Flex alignItems={'center'}>
            <Button 
            borderRadius={'md'} 
            border={'solid 1px'}
            bgColor={'#3c78d8'}
            color={'white'}
            w={'full'}
            _hover={{
              textDecoration: 'none'
            }}
            onClick={handleClick}
              >
              予約する
            </Button>
          </Flex>

        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4} onClick={isOpen ? onClose : onOpen}>
            <Text
                  fontSize={'16px'}
                  color={'#3c78d8'}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'white',
                  }}
                  cursor={'pointer'}
                  onClick={moveToPlans}>
                  出店プラン
                </Text>
                <Text
                  fontSize={'16px'}
                  color={'#3c78d8'}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'white',
                  }}
                  cursor={'pointer'}
                  onClick={moveToNews}>
                  ニュース
                </Text>
                <Text
                  fontSize={'16px'}
                  color={'#3c78d8'}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'white',
                  }}
                  cursor={'pointer'}
                  onClick={moveToNotices}
                  >
                  注意事項
                </Text>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default NavBar