import React from "react"
import {
    Stack,
    VStack,
    HStack,
    Link,
    Text
  } from '@chakra-ui/react';

import { Link as GatsbyLink } from "gatsby"
import { EmailIcon, ExternalLinkIcon } from '@chakra-ui/icons'

const footer: React.FC = () => {
    return (
        <VStack width={'full'} bgColor={'#1255cc'} marginTop={'100px'} color={'white'} paddingBottom={'10px'} >
            <Stack w={'full'} maxW={'1140px'} spacing={1} py={7} px={5}>
                <Text fontWeight={'bold'} fontSize={'2xl'}>主催：B SIDE CLUB<Text as={'span'} fontSize={'md'}>（ベイサイド株式会社）</Text></Text>
                <HStack width={'full'} spacing={5} fontSize={'sm'}>
                    <Stack h={'110px'} width={'48%'}>
                        <Link fontWeight={'bold'} as={GatsbyLink} to={`/privacy`}>プライバシーポリシー</Link>
                        <Link fontWeight={'bold'} href='mailto:kkaayyoo2327+bsideclub@gmail.com?subject=七里ヶ浜フリーマーケットについてのお問合せ' isExternal>お問合せ <EmailIcon mx='2px' mb='2px'/></Link>
                        <Link fontWeight={'bold'} href='http://ameblo.jp/bsideclub/' isExternal>ブログ<ExternalLinkIcon mx='2px' mb='2px'/></Link>
                    </Stack>
                    <Stack h={'110px'} width={'48%'}>
                        <Link fontWeight={'bold'} href='https://twitter.com/staff83277920/' isExternal>Twitter<ExternalLinkIcon mx='2px' mb='2px'/></Link>
                        <Link fontWeight={'bold'} href='https://www.instagram.com/fleamarket_shichiri/' isExternal>Instagram<ExternalLinkIcon mx='2px' mb='2px'/></Link>
                    </Stack>
                </HStack>
            </Stack>
            <Text color={'white'}  fontSize={'sm'}>Copyright ©️ BAYSIDE CO.,LTD. All Rights Reserved.</Text>
        </VStack>

  )
}
export default footer